<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">测评管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">优惠券管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox searMg">
            <div title="渠道名称" class="searchboxItem ci-full">
              <span class="itemLabel">渠道名称:</span>
              <el-input v-model="retrievalData.channelName" type="text" size="small" placeholder="请输入渠道名称" clearable />
            </div>
            <div title="优惠券名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem;">优惠券名称:</span>
              <el-input v-model="retrievalData.discountName" type="text" size="small" placeholder="请输入优惠券名称" clearable />
            </div>
            <div title="优惠券状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">优惠券状态:</span>
              <el-select size="small" v-model="retrievalData.publishState" clearable placeholder="请选择">
                <el-option v-for="item in publishStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker v-model="retrievalData.creationTime" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="newEditor()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="优惠券名称" align="left" show-overflow-tooltip prop="discountName" minWidth="150" />
              <el-table-column label="使用范围" align="left" show-overflow-tooltip prop="discountType" minWidth="100" >
                <template slot-scope="{ row }">
                  {{ $setDictionary("HR_PSY_DISCOUNT_TYPE", row.discountType) }}
                </template>
              </el-table-column>
              <el-table-column label="内容" align="left" show-overflow-tooltip prop="paperName" minWidth="180" />
              <el-table-column label="发行总量" align="left" show-overflow-tooltip prop="totalNum" minWidth="100" />
              <el-table-column label="已领取" align="left" show-overflow-tooltip prop="usedNum" minWidth="100" />
              <el-table-column label="剩余数量" align="left" show-overflow-tooltip prop="lastNum" minWidth="100" />
              <el-table-column label="指定渠道" align="left" show-overflow-tooltip prop="channelName" minWidth="150" />
              <el-table-column label="用券时间" align="left" prop="endDate" show-overflow-tooltip min-width="240">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} - {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="left" prop="publishState" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("EVALUATE_DISCOUNT_STATE", scope.row.publishState) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="180px"><template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="newEditor(scope.row)">{{
                    scope.row.publishState == '10' ? '编辑' : '查看' }}</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="releasedFromShelves(scope.row)"
                    :disabled="scope.row.publishState == '30'">{{
                    scope.row.publishState == '10' ? '发布' : '下架' }}</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="downloadQrCode(scope.row)"
                    :disabled="scope.row.publishState != '20' || scope.row.usedNum >= scope.row.totalNum">下载二维码</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增&编辑 -->
    <el-dialog :title="newEditorData.discountId ? '编辑' : '新增'" :visible.sync="newEditorData.docsDialogVisible"
      width="600px" top="10%" :center="true" size="small" :before-close="closenewEditorData"><el-form ref="newEditorData"
        :model="newEditorData" :rules="newEditorDataRules" label-width="100px"><el-form-item label="优惠券名称"
          prop="discountName">
          <el-input type="text" size="small" placeholder="请输入优惠券名称" v-model="newEditorData.discountName">
          </el-input>
        </el-form-item>
        <el-form-item label="使用范围" prop="discountType">
          <el-radio-group v-model="newEditorData.discountType">
            <el-radio v-for="item in usingRangeList" :key="item.value" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="内容" prop="paperId">
          <el-select clearable v-model="newEditorData.paperId" filterable size="small" remote :remote-method="searchCopmp"
            @change="setSearchCopmpPrice" style="width: 210px;float: left;margin-right: 20px;" placeholder="请至少输入两个字搜索测评">
            <el-option v-for="item in measurementData" :key="item.paperId" :label="item.paperName"
              :value="item.paperId"></el-option>
          </el-select>
          <span style="font-size: 18px;color: red;" v-if="newEditorData.price">￥ {{ newEditorData.price }}</span>
        </el-form-item>
        <el-form-item label="优惠金额" prop="discountMoney">
          <span>无门槛，直减</span>
          <el-input type="text" size="small" style="width: 120px;margin: 0 5px;" placeholder="请输入优惠金额"
            v-model="newEditorData.discountMoney"
            @keyup.native="newEditorData.discountMoney = zF.oninput2(newEditorData.discountMoney, 2)"></el-input>
          <span>元</span>
        </el-form-item>
        <el-form-item label="用券时间" prop="useRollTime">
          <el-date-picker v-model="newEditorData.useRollTime" size="small" type="datetimerange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" />
        </el-form-item>
        <el-form-item label="发行总量" prop="totalNum">
          <el-input type="text" size="small" style="width: 120px;margin: 0 5px;" placeholder="请输入发行总量"
            v-model="newEditorData.totalNum"
            @keyup.native="newEditorData.totalNum = zF.positiveInteger(newEditorData.totalNum)"></el-input>
          <span>张</span>
        </el-form-item>
        <el-form-item label="指定渠道" prop="channelKey">
          <el-select clearable v-model="newEditorData.channelKey" filterable size="small" remote
            :remote-method="channelData" placeholder="请至少输入两个字搜索">
            <el-option v-for="item in specifyChannelData" :key="item.channelKey" :label="item.channelName"
              :value="item.channelKey"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <div>
          <el-button @click="closenewEditorData">取消</el-button>
          <el-button class="bgc-bv" @click="oknewEditorData" v-if="newEditorData.publishState == '10'">保存</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 下载二维码 -->
    <el-dialog title="下载二维码" :visible.sync="downloadQrCodeData.dialogVisible" @closed="downloadQrCodeDialogClosed"
      width="400px">
      <img :src="downloadQrCodeData.viewURL" style="width: 300px;height: 300px; display: block;margin: auto;" alt="">
      <div slot="footer" class="dialog-footer">
        <el-button class="customBtn" round @click="downloadQrCodeDialogClosed">取 消</el-button>
        <el-button class="customBtn" round type="primary" @click="downloadQrCodeDataOk">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "classListCom_classEndReminder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        discountName: "", // 优惠券名称
        channelName: "", // 渠道名称
        publishState: "", // 优惠券状态
        creationTime: "", // 创建时间
      },
      // 发布状态数据s
      publishStateList: [],
      // 使用范围数据
      usingRangeList: [{
        value: '0',
        label: '指定测评可用'
      }],
      // 测评内容数据
      measurementData: [],
      // 指定渠道数据
      specifyChannelData: [],
      // 新增&编辑 - 弹框数据
      newEditorData: {
        docsDialogVisible: false, // 弹框状态
        publishState: "10", // 发布状态: 10未发布 20已发布 30已下架
        discountId: "", // 优惠券id
        discountName: "", // 优惠券名称
        discountType: "0", // 使用范围
        paperId: "", // 测评名称
        price: "", // 测评金额
        discountMoney: "", // 优惠券金额
        useRollTime: "", // 用券时间
        totalNum: "", // 发行总量
        channelKey: "", // 指定渠道
      },
      // 新增&编辑 - 数据校验
      newEditorDataRules: {
        discountName: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        discountType: [
          { required: true, message: "请选择使用范围", trigger: "change" },
        ],
        paperId: [
          { required: true, message: "请选择内容", trigger: "change" },
        ],
        useRollTime: [
          { required: true, message: '请选择用券时间', trigger: 'change' }
        ],
        discountMoney: [
          { required: true, message: "请输入优惠券金额", trigger: "blur" },
        ],
        totalNum: [
          { required: true, message: "请输入发行总量", trigger: "blur" },
        ],
        channelKey: [
          { required: true, message: "请选择指定渠道", trigger: "change" },
        ],
      },
      // 下载二维码 - 弹框数据
      downloadQrCodeData: {
        dialogVisible: false, // 弹框状态
        downloadURL: "", // 下载地址
        viewURL: "" // 预览地址
      }
    };
  },
  created() {
    this.getDictionary();
  },
  watch: {},
  computed: {},
  mounted() {},
  beforeRouteLeave: resetKeepAlive,
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 发布状态
      let publishState = this.$setDictionary("EVALUATE_DISCOUNT_STATE", "list");
      for (const key in publishState) {
        this.publishStateList.push({
          value: key,
          label: publishState[key],
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.discountName) {
        params.discountName = this.retrievalData.discountName;
      }
      if (this.retrievalData.channelName) {
        params.channelName = this.retrievalData.channelName;
      }
      if (this.retrievalData.publishState) {
        params.publishState = this.retrievalData.publishState;
      }
      if (this.retrievalData.creationTime) {
        params.startDate = this.retrievalData.creationTime[0];
        params.endDate = this.retrievalData.creationTime[1];
      }
      this.doFetch({
        url: "/biz/hr_evaluate/discount/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 获取 - 测评数据
    searchCopmp(paperName) {
      if (paperName.trim().length >= 2) {
        this.$post("/biz/hr/evaluate/paper/pageListJG", { paperName, free: false }, 3000, true, 6).then(
          (res) => {
            this.measurementData = res.data?.list ?? [];
            this.setSearchCopmpPrice();
          }
        );
      } else {
        this.measurementData = [];
      }
    },
    // 选择测评数据 - 获取当前价钱
    setSearchCopmpPrice() {
      if (this.newEditorData.paperId) {
        for (let i = 0; i < this.measurementData.length; i++) {
          if (this.measurementData[i].paperId == this.newEditorData.paperId) {
            this.newEditorData.price = String(this.measurementData[i].sellingPrice);
          }
        }
      } else {
        this.newEditorData.price = "";
      }
    },
    // 获取 - 渠道数据
    channelData(channelName) {
      if (channelName.trim().length >= 2) {
        this.$post("/biz/hr/evaluate/psy/channel/pageList", { 
          channelName,
          usable: true, // 是否启用（就穿true,后台让先写死）
        }, 3000, true, 6).then(
          (res) => {
            this.specifyChannelData = res.data?.list ?? [];
          }
        );
      } else {
        this.specifyChannelData = [];
      }
    },
    // 新增&编辑 - 设置
    newEditor(row) {
      if (row) {
        this.newEditorData = {
          ...this.newEditorData,
          ...row
        }
        this.searchCopmp(row.paperName);
        this.channelData(row.channelName);
        this.newEditorData.useRollTime = [row.startTime.replaceAll('/', '-'), row.endTime.replaceAll('/', '-')]
      }
      this.newEditorData.docsDialogVisible = true;
    },
    // 新增&编辑 - 确定
    oknewEditorData() {
      this.$refs["newEditorData"].validate((valid) => {
        if (valid) {
          let tsText = "";
          if(Number(this.newEditorData.discountMoney) / Number(this.newEditorData.price) >= .5 ){
            tsText = '优惠价格已经超过售卖价格的50%，';
          }
          this.$confirm(tsText + "确认优惠券信息是否正确？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let cUrl = '';
              let cData = {
                ...this.newEditorData
              }
              cData.startTime = this.newEditorData.useRollTime[0];
              cData.endTime = this.newEditorData.useRollTime[1];
              if(this.newEditorData.discountId){
                cUrl = '/biz/hr_evaluate/discount/modifyState';
              } else {
                cUrl = '/biz/hr_evaluate/discount/insert';
              }
              this.$post(cUrl, cData, 3000, true, 6)
                .then((res) => {
                  if (res.status == 0) {
                    this.getData();
                    this.closenewEditorData();
                    this.$message({
                      message: res.message,
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取操作",
              });
            });
        }
      });
    },
    // 新增&编辑 - 关闭
    closenewEditorData() {
      this.$refs['newEditorData'].resetFields();
      this.newEditorData = this.$options.data().newEditorData;
    },
    // 发布&下架
    releasedFromShelves(row) {
      let cData = {
        discountId: row.discountId
      }
      if (row.publishState == '10') {
        cData.publishState = '20';
      }
      if (row.publishState == '20') {
        cData.publishState = '30';
      }
      if(row.publishState == '20'){
        this.$post("/biz/hr_evaluate/discount/modifyState", cData, 3000, true, 6)
          .then((res) => {
            if (res.status == 0) {
              this.getData(this.pageNum);
              this.$message({
                message: res.message,
                type: "success",
              });
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.$confirm("优惠券一旦发布不可修改，确认优惠券信息是否正确？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$post("/biz/hr_evaluate/discount/modifyState", cData, 3000, true, 6)
              .then((res) => {
                if (res.status == 0) {
                  this.getData(this.pageNum);
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                }
              })
              .catch(() => {
                return;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取操作",
            });
          });
      }
    },
    // 下载二维码
    downloadQrCode(row) {
      this.$post("/biz/hr_evaluate/discount/downloadQrCode", { discountId: row.discountId }, 3000, true, 6)
        .then((res) => {
          if (res.status == 0) {
            this.downloadQrCodeData = {
              ...this.downloadQrCodeData, ...res.data
            }
            this.downloadQrCodeData.dialogVisible = true;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 下载二维码 - 确定
    downloadQrCodeDataOk(){
      window.open(this.downloadQrCodeData.downloadURL);
    },
    // 下载二维码 - 关闭
    downloadQrCodeDialogClosed() {
      this.downloadQrCodeData = this.$options.data().downloadQrCodeData;
    },
  },
};
</script>
<style lang="less" scoped></style>
  